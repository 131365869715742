import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivationStart, EventType, Router, RouterOutlet } from '@angular/router';
import { filter, map, switchMap } from 'rxjs';
import { TranslatePipe } from '@ngx-translate/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { MessageService, PrimeNGConfig } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';

import { AuthStore, RootStore } from '@dps/core/store';
import { AppRouteEnum } from './app.routes.model';
import { CompanyRoutePathParam } from './pages/company/company.routes.model';
import { APP_UPDATE_TOAST_KEY, AppUpdateService } from '@dps/core/app-update';
import { CompanyApiService } from './core/api';

@UntilDestroy()
@Component({
  selector: 'dps-root',
  standalone: true,
  imports: [RouterOutlet, ToastModule, ButtonModule, TranslatePipe],
  providers: [MessageService],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  readonly appUpdateToastKey = APP_UPDATE_TOAST_KEY;

  constructor(
    public appUpdateService: AppUpdateService,
    private primeNGConfig: PrimeNGConfig,
    private authStore: AuthStore,
    private router: Router,
    private messageService: MessageService,
    private companyApiService: CompanyApiService,
    private rootStore: RootStore
  ) {
    this.router.events
      .pipe(
        filter(event => event.type === EventType.ActivationStart),
        map(event =>
          (event as ActivationStart).snapshot.paramMap.get(CompanyRoutePathParam.COMPANY_ID)
        ),
        filter(Boolean),
        switchMap(companyId => this.companyApiService.getCompany(companyId)),
        untilDestroyed(this)
      )
      .subscribe(company => this.rootStore.setCompany(company));

    this.appUpdateService.updateAvailable$.pipe(untilDestroyed(this)).subscribe(() => {
      this.messageService.add({
        key: APP_UPDATE_TOAST_KEY,
        sticky: true,
        closable: false,
      });
    });
    this.primeNGConfig.ripple = true;

    if (window.location.pathname === '/') {
      this.authStore
        .getCurrUserData$()
        .pipe(
          filter(currUser => !!currUser.managedCompanyId),
          untilDestroyed(this)
        )
        .subscribe(currUser =>
          this.router.navigate([AppRouteEnum.COMPANY, currUser.managedCompanyId])
        );
    }
  }

  activateAppUpdate(): void {
    this.messageService.clear(APP_UPDATE_TOAST_KEY);

    this.appUpdateService
      .activateUpdate()
      .pipe(switchMap(() => this.router.navigateByUrl('')))
      .subscribe(() => window.location.reload());
  }
}
