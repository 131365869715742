import {
  APP_INITIALIZER,
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
  inject,
  isDevMode,
  provideExperimentalZonelessChangeDetection,
} from '@angular/core';
import { provideServiceWorker } from '@angular/service-worker';
import { provideRouter, withViewTransitions } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideHttpClient, withInterceptors } from '@angular/common/http';

import { DialogService } from 'primeng/dynamicdialog';

import { I18nModule } from '@dps/core/i18n';
import { featureFlagFactory, FeatureFlagService } from '@dps/core/feature-flag';
import { authInterceptor, errorInterceptor } from './core/interceptors';
import { RollbarErrorHandler, RollbarService, rollbarFactory } from '@dps/core/rollbar';
import { routes } from './app.routes';

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(withInterceptors([authInterceptor, errorInterceptor])),
    provideRouter(routes, withViewTransitions()),
    provideAnimations(),
    importProvidersFrom(I18nModule),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
    provideExperimentalZonelessChangeDetection(),
    DialogService,
    { provide: ErrorHandler, useClass: RollbarErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory },
    {
      provide: APP_INITIALIZER,
      useFactory: featureFlagFactory,
      multi: true,
      deps: [FeatureFlagService],
    },
  ],
};
