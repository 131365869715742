(function (d, i) {
  var s = typeof exports == "object";
  if (typeof define == "function" && define.amd) define([], i);else if (typeof module == "object" && module.exports) module.exports = i();else {
    var p = i(),
      u = s ? exports : d;
    for (var m in p) u[m] = p[m];
  }
})(typeof self < "u" ? self : this, () => {
  var d = {},
    i = {
      exports: d
    },
    s = Object.defineProperty,
    p = Object.getOwnPropertyDescriptor,
    u = Object.getOwnPropertyNames,
    m = Object.prototype.hasOwnProperty,
    b = (e, a, n) => a in e ? s(e, a, {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: n
    }) : e[a] = n,
    h = (e, a) => {
      for (var n in a) s(e, n, {
        get: a[n],
        enumerable: !0
      });
    },
    w = (e, a, n, r) => {
      if (a && typeof a == "object" || typeof a == "function") for (let t of u(a)) !m.call(e, t) && t !== n && s(e, t, {
        get: () => a[t],
        enumerable: !(r = p(a, t)) || r.enumerable
      });
      return e;
    },
    y = e => w(s({}, "__esModule", {
      value: !0
    }), e),
    f = (e, a, n) => (b(e, typeof a != "symbol" ? a + "" : a, n), n),
    v = {};
  h(v, {
    default: () => D
  }), i.exports = y(v);
  var l = typeof self < "u" ? self : typeof globalThis < "u" ? globalThis : null,
    k = class g {
      static mergeLocales(...a) {
        let n = {};
        return a.forEach(r => {
          Object.keys(r).forEach(t => {
            typeof r[t] == "object" ? n[t] = {
              ...n[t],
              ...r[t]
            } : n[t] = r[t];
          });
        }), n;
      }
      static trimLocale(a, n) {
        let r = (t, o) => {
          a[t] && (o ? a[t][o] && delete a[t][o] : delete a[t]);
        };
        Object.keys(n).forEach(t => {
          Object.keys(n[t]).length > 0 ? Object.keys(n[t]).forEach(o => r(t, o)) : r(t);
        });
      }
      static normalizeLocale(a, n) {
        if (!a) throw new Error('"nameOrConfig" parameter can not be empty');
        if (typeof a == "string") {
          if (!n) throw new Error('"config" parameter can not be empty');
          n.locale ? n.name = a || n.name : n.localeName = a;
        } else n = a;
        let r = {};
        if (n.name || n.locale) r = Object.assign({
          localeName: n.name
        }, n.locale), n.desc && (r.localeDesc = n.desc), n.code && (r.localeCode = n.code), n.path && (r.localePath = n.path);else {
          if (!n.localeName) throw new Error(`"config" parameter doesn't have "localeName" property`);
          r = Object.assign({}, n);
        }
        for (let t of ["name", "desc", "code", "path"]) r[t] && delete r[t];
        if (!r.localeName) throw new Error("Locale name can not be empty");
        return r;
      }
      static get locales() {
        return l.bryntum.locales || {};
      }
      static set locales(a) {
        l.bryntum.locales = a;
      }
      static get localeName() {
        return l.bryntum.locale || "En";
      }
      static set localeName(a) {
        l.bryntum.locale = a || g.localeName;
      }
      static get locale() {
        return g.localeName && this.locales[g.localeName] || this.locales.En || Object.values(this.locales)[0] || {
          localeName: "",
          localeDesc: "",
          localeCoode: ""
        };
      }
      static publishLocale(a, n) {
        let {
            locales: r
          } = l.bryntum,
          t = g.normalizeLocale(a, n),
          {
            localeName: o
          } = t;
        return !r[o] || n === !0 ? r[o] = t : r[o] = this.mergeLocales(r[o] || {}, t || {}), r[o];
      }
    };
  f(k, "skipLocaleIntegrityCheck", !1);
  var c = k;
  l.bryntum = l.bryntum || {}, l.bryntum.locales = l.bryntum.locales || {}, c._$name = "LocaleHelper";
  var j = {
      localeName: "Nl",
      localeDesc: "Nederlands",
      localeCode: "nl",
      Object: {
        Yes: "Ja",
        No: "Nee",
        Cancel: "Annuleren",
        Ok: "OK",
        Week: "Week",
        None: "Geen"
      },
      CodeEditor: {
        apply: "Toepassen",
        autoApply: "Automatisch toepassen",
        downloadCode: "Code downloaden",
        editor: "Code-editor",
        viewer: "Codeviewer"
      },
      ColorPicker: {
        noColor: "Geen kleur"
      },
      Combo: {
        noResults: "Geen resultaten",
        recordNotCommitted: "Record kan niet worden toegevoegd",
        addNewValue: e => `${e} toevoegen`
      },
      FilePicker: {
        file: "Vijl"
      },
      Field: {
        badInput: "Ongeldige veldwaarde",
        patternMismatch: "Waarde moet overeenkomen met een specifiek patroon",
        rangeOverflow: e => `Waarde moet kleiner zijn dan of gelijk aan ${e.max}`,
        rangeUnderflow: e => `Waarde moet groter zijn dan of gelijk aan ${e.min}`,
        stepMismatch: "Waarde moet bij de stap passen",
        tooLong: "Waarde moet korter zijn",
        tooShort: "Waarde moet langer zijn",
        typeMismatch: "Waarde moet een speciaal formaat hebben",
        valueMissing: "Dit veld is verplicht",
        invalidValue: "Ongeldige veldwaarde",
        minimumValueViolation: "Minimale waarde schending",
        maximumValueViolation: "Maximale waarde schending",
        fieldRequired: "Dit veld is verplicht",
        validateFilter: "Waarde moet worden geselecteerd in de lijst"
      },
      DateField: {
        invalidDate: "Ongeldige datuminvoer"
      },
      DatePicker: {
        gotoPrevYear: "Ga naar vorig jaar",
        gotoPrevMonth: "Ga naar vorige maand",
        gotoNextMonth: "Ga naar volgende maand",
        gotoNextYear: "Ga naar volgend jaar"
      },
      NumberFormat: {
        locale: "nl",
        currency: "EUR"
      },
      DurationField: {
        invalidUnit: "Ongeldige eenheid"
      },
      TimeField: {
        invalidTime: "Ongeldige tijdsinvoer"
      },
      TimePicker: {
        hour: "Uur",
        minute: "Minuut",
        second: "Seconde"
      },
      List: {
        loading: "Laden...",
        selectAll: "Alles selecteren"
      },
      GridBase: {
        loadMask: "Laden...",
        syncMask: "Bezig met opslaan..."
      },
      PagingToolbar: {
        firstPage: "Ga naar de eerste pagina",
        prevPage: "Ga naar de vorige pagina",
        page: "Pagina",
        nextPage: "Ga naar de volgende pagina",
        lastPage: "Ga naar de laatste pagina",
        reload: "Laad huidige pagina opnieuw",
        noRecords: "Geen rijen om weer te geven",
        pageCountTemplate: e => `van ${e.lastPage}`,
        summaryTemplate: e => `Records ${e.start} - ${e.end} van ${e.allCount} worden weergegeven`
      },
      PanelCollapser: {
        Collapse: "Klap in",
        Expand: "Klap uit"
      },
      Popup: {
        close: "Sluiten"
      },
      UndoRedo: {
        Undo: "Ongedaan maken",
        Redo: "Opnieuw doen",
        UndoLastAction: "Maak de laatste actie ongedaan",
        RedoLastAction: "Herhaal de laatste ongedaan gemaakte actie",
        NoActions: "Geen items om ongedaan te maken"
      },
      FieldFilterPicker: {
        equals: "gelijk",
        doesNotEqual: "niet gelijk",
        isEmpty: "is leeg",
        isNotEmpty: "is niet leeg",
        contains: "bevat",
        doesNotContain: "bevat geen",
        startsWith: "begint met",
        endsWith: "eindigt met",
        isOneOf: "is een van",
        isNotOneOf: "is niet een van",
        isGreaterThan: "is groter dan",
        isLessThan: "is kleiner dan",
        isGreaterThanOrEqualTo: "is groter of gelijk aan",
        isLessThanOrEqualTo: "is kleiner of gelijk aan",
        isBetween: "zit tussen",
        isNotBetween: "zit niet tussen",
        isBefore: "is voor",
        isAfter: "is na",
        isToday: "is vandaag",
        isTomorrow: "is morgen",
        isYesterday: "is gisteren",
        isThisWeek: "is deze week",
        isNextWeek: "is volgende week",
        isLastWeek: "is afgelopen week",
        isThisMonth: "is deze maand",
        isNextMonth: "is volgende maand",
        isLastMonth: "is vorige maand",
        isThisYear: "is dit jaar",
        isNextYear: "is volgend jaar",
        isLastYear: "is vorige jaar",
        isYearToDate: "is dit jaar tot vandaag",
        isTrue: "is waar",
        isFalse: "is niet waar",
        selectAProperty: "Selecteer een veld",
        selectAnOperator: "Selecteer een operator",
        caseSensitive: "Hoofdletter gevoelig",
        and: "en",
        dateFormat: "D/M/YYYY",
        selectValue: "Selecteer waarde",
        selectOneOrMoreValues: "Selecteer een of meer waarden",
        enterAValue: "Voer een waarde in",
        enterANumber: "Voer een getal in",
        selectADate: "Selecteer een datum",
        selectATime: "Selecteer tijd"
      },
      FieldFilterPickerGroup: {
        addFilter: "Voeg filter toe"
      },
      DateHelper: {
        locale: "nl",
        weekStartDay: 1,
        nonWorkingDays: {
          0: !0,
          6: !0
        },
        weekends: {
          0: !0,
          6: !0
        },
        unitNames: [{
          single: "ms",
          plural: "ms",
          abbrev: "ms"
        }, {
          single: "emillisecond",
          plural: "ems",
          abbrev: "ems"
        }, {
          single: "seconde",
          plural: "seconden",
          abbrev: "s"
        }, {
          single: "esecond",
          plural: "eseconds",
          abbrev: "es"
        }, {
          single: "minuut",
          plural: "minuten",
          abbrev: "m"
        }, {
          single: "eminute",
          plural: "eminutes",
          abbrev: "emin"
        }, {
          single: "uur",
          plural: "uren",
          abbrev: "u"
        }, {
          single: "ehour",
          plural: "ehours",
          abbrev: "eh"
        }, {
          single: "dag",
          plural: "dagen",
          abbrev: "d"
        }, {
          single: "eday",
          plural: "edays",
          abbrev: "ed"
        }, {
          single: "week",
          plural: "weken",
          abbrev: "w"
        }, {
          single: "eweek",
          plural: "eweeks",
          abbrev: "ew"
        }, {
          single: "maand",
          plural: "maanden",
          abbrev: "ma"
        }, {
          single: "emonth",
          plural: "emonths",
          abbrev: "emon"
        }, {
          single: "kwartaal",
          plural: "kwartalen",
          abbrev: "kw"
        }, {
          single: "equarter",
          plural: "equarters",
          abbrev: "eq"
        }, {
          single: "jaar",
          plural: "jaren",
          abbrev: "j"
        }, {
          single: "eyear",
          plural: "eyears",
          abbrev: "eyr"
        }, {
          single: "decennium",
          plural: "decennia",
          abbrev: "dec"
        }, {
          single: "edecade",
          plural: "edecades",
          abbrev: "edec"
        }],
        unitAbbreviations: [["mil"], [], ["s", "sec"], [], ["m", "min"], [], ["u"], [], ["d"], [], ["w", "wk"], [], ["ma", "mnd", "m"], [], ["k", "kwar", "kwt", "kw"], [], ["j", "jr"], [], ["dec"], []],
        parsers: {
          L: "DD-MM-YYYY",
          LT: "HH:mm",
          LTS: "HH:mm:ss"
        },
        ordinalSuffix: e => e
      }
    },
    T = c.publishLocale(j),
    E = new String(),
    S = {
      localeName: "Nl",
      localeDesc: "Nederlands",
      localeCode: "nl",
      ColumnPicker: {
        column: "Kolom",
        columnsMenu: "Kolommen",
        hideColumn: "Verberg Kolom",
        hideColumnShort: "Verberg",
        newColumns: "Nieuwe kolommen"
      },
      Filter: {
        applyFilter: "Pas filter toe",
        filter: "Filter",
        editFilter: "Wijzig filter",
        on: "Aan",
        before: "Voor",
        after: "Na",
        equals: "Is gelijk",
        lessThan: "Minder dan",
        moreThan: "Meer dan",
        removeFilter: "Verwijder filter",
        disableFilter: "Uitschakelen filter"
      },
      FilterBar: {
        enableFilterBar: "Maak filterbalk zichtbaar",
        disableFilterBar: "Verberg filterbalk"
      },
      Group: {
        group: "Groepeer",
        groupAscending: "Groepeer oplopend",
        groupDescending: "Groepeer aflopend",
        groupAscendingShort: "Oplopend",
        groupDescendingShort: "Aflopend",
        stopGrouping: "Maak groepering ongedaan",
        stopGroupingShort: "Maak ongedaan"
      },
      HeaderMenu: {
        moveBefore: e => `Verplaatsen naar voor "${e}"`,
        moveAfter: e => `Verplaatsen naar na "${e}"`,
        collapseColumn: "Kolom inklappen",
        expandColumn: "Kolom uitklappen"
      },
      ColumnRename: {
        rename: "Hernoemen"
      },
      MergeCells: {
        mergeCells: "Cellen samenvoegen",
        menuTooltip: "Met deze kolom sortering cellen met dezelfde waarde samenvoegen"
      },
      Search: {
        searchForValue: "Zoek op term"
      },
      Sort: {
        sort: "Sorteer",
        sortAscending: "Sorteer oplopend",
        sortDescending: "Sorteer aflopend",
        multiSort: "Meerdere sorteringen",
        removeSorter: "Verwijder sortering",
        addSortAscending: "Voeg oplopende sortering toe",
        addSortDescending: "Voeg aflopende sortering toe",
        toggleSortAscending: "Sorteer oplopend",
        toggleSortDescending: "Sorteer aflopend",
        sortAscendingShort: "Oplopend",
        sortDescendingShort: "Aflopend",
        removeSorterShort: "Verwijder",
        addSortAscendingShort: "+ Oplopend",
        addSortDescendingShort: "+ Aflopend"
      },
      Split: {
        split: "Gesplitst",
        unsplit: "Ongeplitst",
        horizontally: "Horizontaal",
        vertically: "Verticaal",
        both: "Beide"
      },
      LockRows: {
        lockRow: "Rij vergrendelen",
        unlockRow: "Rij ontgrendelen"
      },
      Column: {
        columnLabel: e => `${e.text ? `${e.text} kolom. ` : ""}SPATIEBALK voor contextmenu${e.sortable ? ", ENTER om te sorteren" : ""}`,
        cellLabel: E
      },
      Checkbox: {
        toggleRowSelect: "Rijselectie wisselen",
        toggleSelection: "Toggle selectie van volledige dataset"
      },
      RatingColumn: {
        cellLabel: e => {
          var a;
          return `${e.text ? e.text : ""} ${(a = e.location) != null && a.record ? `rating : ${e.location.record.get(e.field) || 0}` : ""}`;
        }
      },
      GridBase: {
        loadFailedMessage: "Laden mislukt!",
        syncFailedMessage: "Gegevenssynchronisatie is mislukt!",
        unspecifiedFailure: "Niet-gespecificeerde fout",
        networkFailure: "Netwerk fout",
        parseFailure: "Kan server response niet parsen",
        serverResponse: "Server reactie:",
        noRows: "Geen rijen om weer te geven",
        moveColumnLeft: "Plaats naar het linker kader",
        moveColumnRight: "Plaats naar het rechter kader",
        moveColumnTo: e => `Kolom verplaatsen naar ${e}`
      },
      CellMenu: {
        removeRow: "Verwijder"
      },
      RowCopyPaste: {
        copyRecord: "Kopieer",
        cutRecord: "Knip",
        pasteRecord: "Plak",
        rows: "rijen",
        row: "row"
      },
      CellCopyPaste: {
        copy: "Kopieer",
        cut: "Knip",
        paste: "Plak"
      },
      PdfExport: {
        "Waiting for response from server": "Wachten op antwoord van server...",
        "Export failed": "Export mislukt",
        "Server error": "Serverfout",
        "Generating pages": "Pagina's genereren...",
        "Click to abort": "Annuleren"
      },
      ExportDialog: {
        width: "40em",
        labelWidth: "12em",
        exportSettings: "Instellingen exporteren",
        export: "Exporteren",
        printSettings: "Afdrukinstellingen",
        print: "Afdrukken",
        exporterType: "Paginering beheren",
        cancel: "Annuleren",
        fileFormat: "Bestandsformaat",
        rows: "Rijen",
        alignRows: "Rijen uitlijnen",
        columns: "Kolommen",
        paperFormat: "Papier formaat",
        orientation: "Oriëntatatie",
        repeatHeader: "Herhaal koptekst"
      },
      ExportRowsCombo: {
        all: "Alle rijen",
        visible: "Zichtbare rijen"
      },
      ExportOrientationCombo: {
        portrait: "Staand",
        landscape: "Liggend"
      },
      SinglePageExporter: {
        singlepage: "Enkele pagina"
      },
      MultiPageExporter: {
        multipage: "Meerdere pagina's",
        exportingPage: ({
          currentPage: e,
          totalPages: a
        }) => `Exporteren van de pagina ${e}/${a}`
      },
      MultiPageVerticalExporter: {
        multipagevertical: "Meerdere pagina's (verticaal)",
        exportingPage: ({
          currentPage: e,
          totalPages: a
        }) => `Exporteren van de pagina ${e}/${a}`
      },
      RowExpander: {
        loading: "Bezig met laden",
        expand: "Klap uit",
        collapse: "Klap in"
      },
      TreeGroup: {
        group: "Groeperen op",
        stopGrouping: "Stop groeperen",
        stopGroupingThisColumn: "Groeperen opheffen voor deze kolom"
      }
    },
    N = c.publishLocale(S),
    C = {
      localeName: "Nl",
      localeDesc: "Nederlands",
      localeCode: "nl",
      Object: {
        newEvent: "Nieuwe boeking"
      },
      ResourceInfoColumn: {
        eventCountText: e => e + " evenement" + (e !== 1 ? "en" : "")
      },
      Dependencies: {
        from: "Van",
        to: "Naar",
        valid: "Geldig",
        invalid: "Ongeldig"
      },
      DependencyType: {
        SS: "GB",
        SF: "BE",
        FS: "EB",
        FF: "GE",
        StartToStart: "Gelijk-Begin",
        StartToEnd: "Begin-na-Einde",
        EndToStart: "Einde-na-Begin",
        EndToEnd: "Gelijk-Einde",
        short: ["GB", "BE", "EB", "GE"],
        long: ["Gelijk-Begin", "Begin-na-Einde", "Einde-na-Begin", "Gelijk-Einde"]
      },
      DependencyEdit: {
        From: "Van",
        To: "Tot",
        Type: "Type",
        Lag: "Achterstand",
        "Edit dependency": "Afhankelijkheid bewerken",
        Save: "Bewaar",
        Delete: "Verwijder",
        Cancel: "Annuleer",
        StartToStart: "Begin-Tot-Begin",
        StartToEnd: "Begin-Tot-Einde",
        EndToStart: "Einde-Tot-Start",
        EndToEnd: "Einde-Tot-Einde"
      },
      EventEdit: {
        Name: "Naam",
        Resource: "Resource",
        Start: "Start",
        End: "Eind",
        Save: "Bewaar",
        Delete: "Verwijder",
        Cancel: "Annuleer",
        "Edit event": "Wijzig item",
        Repeat: "Herhaal"
      },
      EventDrag: {
        eventOverlapsExisting: "Gebeurtenis overlapt bestaande gebeurtenis voor deze bron",
        noDropOutsideTimeline: "Evenement kan niet volledig buiten de tijdlijn worden verwijderd"
      },
      SchedulerBase: {
        "Add event": "Voeg evenement toe",
        "Delete event": "Verwijder item",
        "Unassign event": "Gebeurtenis ongedaan maken",
        color: "Kleur"
      },
      TimeAxisHeaderMenu: {
        pickZoomLevel: "Zoom in",
        activeDateRange: "Datum bereik",
        startText: "Start datum",
        endText: "Eind datum",
        todayText: "Vandaag"
      },
      EventCopyPaste: {
        copyEvent: "Kopieer item",
        cutEvent: "Knip item",
        pasteEvent: "Plak item"
      },
      EventFilter: {
        filterEvents: "Filter items",
        byName: "Op naam"
      },
      TimeRanges: {
        showCurrentTimeLine: "Maak huidige tijdlijn zichtbaar"
      },
      PresetManager: {
        secondAndMinute: {
          name: "Seconden"
        },
        minuteAndHour: {
          topDateFormat: "ddd DD-MM, hh"
        },
        hourAndDay: {
          topDateFormat: "ddd DD-MM",
          name: "Dag"
        },
        day: {
          name: "Dag/uren"
        },
        week: {
          name: "Week/uren"
        },
        dayAndWeek: {
          displayDateFormat: "ll LST",
          name: "Week/dagen"
        },
        dayAndMonth: {
          name: "Maand"
        },
        weekAndDay: {
          displayDateFormat: "hh:mm",
          name: "Week"
        },
        weekAndMonth: {
          name: "Weken"
        },
        weekAndDayLetter: {
          name: "Weken/doordeweekse dagen"
        },
        weekDateAndMonth: {
          name: "Maanden/weken"
        },
        monthAndYear: {
          name: "Maanden"
        },
        year: {
          name: "Jaren"
        },
        manyYears: {
          name: "Несколько лет"
        }
      },
      RecurrenceConfirmationPopup: {
        "delete-title": "U verwijdert een plan item",
        "delete-all-message": "Wilt u alle herhaalde afspraken van dit item verwijderen?",
        "delete-further-message": "Wilt u het geselecteerde en alle toekomstige gebeurtenissen van dit item verwijderen, of aleen het geselecteerde item?",
        "delete-only-this-message": "Wilt u dit voorkomen van dit evenement verwijderen?",
        "delete-further-btn-text": "Verwijder alleen de toekomstige gebeurtenissen",
        "delete-only-this-btn-text": "Verwijder alleen deze gebeurtenis",
        "update-title": "U verandert een herhaald item",
        "update-all-message": "Wilt u alle herhaalde afspraken van dit item verwijderen?",
        "update-further-message": "Wilt u het geselecteerde en alle toekomstige gebeurtenissen van dit item wijzigen, of aleen het geselecteerde item?",
        "update-only-this-message": "Wilt u dit voorkomen van dit evenement wijzigen?",
        "update-further-btn-text": "Wijzig alle toekomstige items",
        "update-only-this-btn-text": "Wijzig alleen dit item",
        Yes: "Ja",
        Cancel: "Annuleer",
        width: 600
      },
      RecurrenceLegend: {
        " and ": " en ",
        Daily: "Dagelijks",
        "Weekly on {1}": ({
          days: e
        }) => `Wekelijks op ${e}`,
        "Monthly on {1}": ({
          days: e
        }) => `Maandelijks op ${e}`,
        "Yearly on {1} of {2}": ({
          days: e,
          months: a
        }) => `Jaarlijks op ${e} ${a}`,
        "Every {0} days": ({
          interval: e
        }) => `Elke ${e} dagen`,
        "Every {0} weeks on {1}": ({
          interval: e,
          days: a
        }) => `Elke ${e} weken op ${a}`,
        "Every {0} months on {1}": ({
          interval: e,
          days: a
        }) => `Elke ${e} maanden in ${a}`,
        "Every {0} years on {1} of {2}": ({
          interval: e,
          days: a,
          months: n
        }) => `Elke ${e} jaar op ${a} ${n}`,
        position1: "de eerste",
        position2: "de tweede",
        position3: "de derde",
        position4: "de vierde",
        position5: "de vijfde",
        "position-1": "laatste",
        day: "dag",
        weekday: "weekdag",
        "weekend day": "weekend dag",
        daysFormat: ({
          position: e,
          days: a
        }) => `${e} ${a}`
      },
      RecurrenceEditor: {
        "Repeat event": "Herhaal gebeurtenis",
        Cancel: "Annuleer",
        Save: "Bewaar",
        Frequency: "Frequentie",
        Every: "Elke",
        DAILYintervalUnit: "dag(en)",
        WEEKLYintervalUnit: "week(en)",
        MONTHLYintervalUnit: "maand(en)",
        YEARLYintervalUnit: "jaren(en)",
        Each: "Elke",
        on: "Op",
        the: "De",
        "On the": "Op de",
        "End repeat": "Einde herhaling",
        "time(s)": "tijd(en)",
        Days: "Dagen",
        Months: "Maanden"
      },
      RecurrenceDaysCombo: {
        day: "dag",
        weekday: "weekdag",
        "weekend day": "weekend dag"
      },
      RecurrencePositionsCombo: {
        position1: "eerste",
        position2: "tweede",
        position3: "derde",
        position4: "vierde",
        position5: "vijfde",
        "position-1": "laatste"
      },
      RecurrenceStopConditionCombo: {
        Never: "Nooit",
        After: "Na",
        "On date": "Op datum"
      },
      RecurrenceFrequencyCombo: {
        None: "Geen herhaling",
        Daily: "Dagelijks",
        Weekly: "Wekelijks",
        Monthly: "Maandelijks",
        Yearly: "Jaarlijks"
      },
      RecurrenceCombo: {
        None: "Geen",
        Custom: "Aangepast..."
      },
      Summary: {
        "Summary for": e => `Samenvatting voor ${e}`
      },
      ScheduleRangeCombo: {
        completeview: "Compleet schema",
        currentview: "Huidige weergave",
        daterange: "Periode",
        completedata: "Alle data (events)"
      },
      SchedulerExportDialog: {
        "Schedule range": "Scheduler bereik",
        "Export from": "Vanaf",
        "Export to": "Naar"
      },
      ExcelExporter: {
        "No resource assigned": "Geen resource toegewezen"
      },
      CrudManagerView: {
        serverResponseLabel: "Server reactie:"
      },
      DurationColumn: {
        Duration: "Duur"
      }
    },
    D = c.publishLocale(C);
  if (typeof i.exports == "object" && typeof d == "object") {
    var x = (e, a, n, r) => {
      if (a && typeof a == "object" || typeof a == "function") for (let t of Object.getOwnPropertyNames(a)) !Object.prototype.hasOwnProperty.call(e, t) && t !== n && Object.defineProperty(e, t, {
        get: () => a[t],
        enumerable: !(r = Object.getOwnPropertyDescriptor(a, t)) || r.enumerable
      });
      return e;
    };
    i.exports = x(i.exports, d);
  }
  return i.exports;
});