<router-outlet></router-outlet>

<p-toast [key]="appUpdateToastKey" [preventOpenDuplicates]="true">
  <ng-template pTemplate="headless" let-closeFn="closeFn">
    <div class="flex flex-column dps-bg-color-black-olive gap-2 p-3">
      <span class="text-white">{{ 'APP_UPDATE_TOAST.MESSAGE' | translate }}</span>

      <div class="flex gap-2">
        <p-button
          [autofocus]="true"
          [label]="'APP_UPDATE_TOAST.UPDATE_NOW_BUTTON' | translate"
          (onClick)="activateAppUpdate()"
        >
        </p-button>
        <p-button
          [label]="'APP_UPDATE_TOAST.UPDATE_LATER_BUTTON' | translate"
          [text]="true"
          styleClass="text-white"
          (onClick)="closeFn($event)"
        >
        </p-button>
      </div>
    </div>
  </ng-template>
</p-toast>
